<template>
    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="show" v-on:click:outside="cancelMethod">
        <v-card>
            <v-toolbar color="grey lighten-1" class="text-h5">
                {{ newRecord ? 'Inserisci Timbratura' : 'Modifica Timbratura' }}
                <v-spacer></v-spacer>
                <v-btn icon @click="cancelMethod">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container class="py-8 pa-4">
                <v-row>
                    <v-col>
                        <v-row class="px-6">
                            <div class="text-subtitle-2">
                                Ingresso
                            </div>
                        </v-row>
                        <v-row class="d-flex justify-center align-center">
                            <v-sheet height="80" rounded="lg" color="grey lighten-4"
                                class="d-flex justify-center align-center px-4">
                                <div class="text-h3">
                                    <TimeInput :valueTime.sync="editedPunchInTime" :prependIcon="'mdi-import'"
                                        v-on:selected-hour="adjustAllowedMinutes" v-on:selected-minute="adjustPunchOutTime"
                                        :allowedHours="allowedHours" :allowedMinutes="allowedMinutes" />
                                </div>

                            </v-sheet>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row class="px-6">
                            <div class="text-subtitle-2">
                                Uscita
                            </div>
                        </v-row>
                        <v-row class="d-flex justify-center align-center">
                            <v-sheet height="80" rounded="lg" color="grey lighten-4"
                                class="d-flex justify-center align-center px-4">
                                <div class="text-h3">
                                    <TimeInput :valueTime.sync="editedPunchOutTime" :prependIcon="'mdi-export'"
                                        v-on:selected-hour="adjustAllowedMinutes" :allowedHours="allowedHours"
                                        :allowedMinutes="allowedMinutes" :disabled="!editedPunchInTime"
                                        :min="editedPunchInTime" :max="nextTimeRangeStart" />
                                </div>
                            </v-sheet>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions class="justify-end">
                <v-btn color="red lighten-2" class="px-4" @click="deleteMethod" v-if="!newRecord">
                    <v-icon left>
                        mdi-delete
                    </v-icon>
                    Elimina
                </v-btn>
                <v-btn :color="newRecord ? 'green accent-2' : 'amber lighten-1'" class="px-4" @click="saveMethod"
                    :disabled="saveDisabled">
                    <v-icon left>
                        mdi-check
                    </v-icon>
                    {{ newRecord ? 'Crea' : 'Modifica' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export const TimeInput = () => import('../../global/TimeInput.vue')

import { DateTime } from 'luxon';

export default {
    data: function () {
        return {
            editedPunchInTime: null,
            editedPunchOutTime: null,
            selectedHour: null,
        }
    },
    props: {
        record: {
            type: Object,
            default: function () {
                return {}
            }
        },
        show: {
            type: Boolean,
            required: true
        },
        forbiddenTimeRanges: {
            type: Array,
            default: function () {
                return [];
            }
        },
        dayRecordsDate: {
            type: Date,
            required: true
        }
    },
    components: {
        TimeInput
    },
    methods: {
        cancelMethod: function () {
            this.$emit('close-time-record-edit-dialog');
        },
        deleteMethod: function () {
            this.$emit('close-time-record-edit-dialog');
            this.$emit('delete-time-record', (this.record || {}).dateIndex);
        },
        saveMethod: function () {
            this.$emit('save-time-record', { punchInTime: this.wrappedEditedPunchIn.toISO(), punchOutTime: this.wrappedEditedPunchOut.toISO(), dateIndex: this.newRecord ? null : (this.record || {}).dateIndex });
        },
        adjustAllowedMinutes: function (selectedHour) {
            this.selectedHour = selectedHour;
        },
        adjustPunchOutTime: function () {
            if (this.wrappedEditedPunchIn && this.wrappedEditedPunchOut && this.wrappedEditedPunchIn > this.wrappedEditedPunchOut)
                this.editedPunchOutTime = null;
        }
    },
    watch: {
        // record: {
        //     handler: function(val) {
        //          
        //     },
        //     deep: true
        // }
        show: function (val) {
            if ((this.record || {}).clockRecord) {
                this.editedPunchInTime = (this.record || {}).clockRecord.punchInTime;
                this.editedPunchOutTime = (this.record || {}).clockRecord.punchOutTime;
            } else {
                this.editedPunchInTime = null;
                this.editedPunchOutTime = null;
            }
        },
        // editedPunchInTime: function(val) {
        //     if(this.wrappedEditedPunchIn && this.wrappedEditedPunchOut && this.wrappedEditedPunchIn > this.wrappedEditedPunchOut)
        //         this.editedPunchOutTime = null;
        // }
    },
    computed: {
        wrappedEditedPunchIn: function () {
            if (this.editedPunchInTime) {
                return DateTime.fromISO(this.editedPunchInTime).set({ year: this.wrappedRecordsDate.year, month: this.wrappedRecordsDate.month, day: this.wrappedRecordsDate.day });
            } else {
                return null;
            }
        },
        wrappedEditedPunchOut: function () {
            if (this.editedPunchOutTime) {
                return DateTime.fromISO(this.editedPunchOutTime).set({ year: this.wrappedRecordsDate.year, month: this.wrappedRecordsDate.month, day: this.wrappedRecordsDate.day });
            } else {
                return null;
            }
        },
        wrappedRecordsDate: function () {
            if (this.dayRecordsDate) {
                return DateTime.fromJSDate(this.dayRecordsDate);
            } else {
                return null;
            }
        },
        newRecord: function () {
            return JSON.stringify((this.record || {})) == JSON.stringify({});
        },
        saveDisabled: function () {
            return !(this.editedPunchInTime && this.editedPunchOutTime) || (this.editedPunchInTime == ((this.record || {}).clockRecord || {}).punchInTime && this.editedPunchOutTime == ((this.record || {}).clockRecord || {}).punchOutTime);
        },
        allowedTimes: function () {
            let minutesArray = [...Array(60).keys()];
            let hoursObj = [];
            for (let i = 1; i <= 24; i++) {
                hoursObj.push([...minutesArray]);
            }
            console.log(this.forbiddenTimeRanges)
            this.forbiddenTimeRanges.forEach(range => {
                if (this.newRecord || range.dateIndex != (this.record || {}).dateIndex) {
                    let start = DateTime.fromISO(range.punchInTime);

                    if (range.punchOutTime) {
                        let stop = DateTime.fromISO(range.punchOutTime);
                        if (start.hour == stop.hour) {
                            hoursObj[start.hour] = hoursObj[start.hour].filter(minute => minute <= start.minute || minute >= stop.minute)
                        } else {
                            hoursObj[start.hour] = hoursObj[start.hour].filter(minute => minute <= start.minute)
                            for (let hour = start.hour + 1; hour < stop.hour; hour++) {
                                hoursObj[hour] = [];
                            }
                            hoursObj[stop.hour] = hoursObj[stop.hour].filter(minute => minute >= stop.minute)
                        }
                    } else {
                        hoursObj[start.hour] = hoursObj[start.hour].filter(minute => minute <= start.minute)
                        for (let hour = start.hour + 1; hour <= 24; hour++) {
                            hoursObj[hour] = [];
                        }
                    }
                }

            })
            return hoursObj;
        },
        nextTimeRangeStart: function () {
            if (this.wrappedEditedPunchIn) {
                let result = this.forbiddenTimeRanges;
                if (!this.newRecord) {
                    result = result.filter(range => (this.record || {}).dateIndex != range.dateIndex);
                }
                result = result.map(range => DateTime.fromISO(range.punchInTime));
                result = result.filter(startTime => this.wrappedEditedPunchIn <= startTime);
                if (result.length > 0) {
                    result = DateTime.min(...result);
                    return result.toISO();
                } else {
                    return null;
                }

            } else {
                return null;
            }

        },
        allowedHours: function (val) {
            return (val) => {
                return this.allowedTimes[val] && this.allowedTimes[val].length > 0
            };
        },
        allowedMinutes: function (val) {
            return (val) => {
                if (this.selectedHour != null && this.selectedHour != undefined) {
                    return this.allowedTimes[this.selectedHour].includes(val);
                } else {
                    return false;
                }
            }
        }
    }
}
</script>